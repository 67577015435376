<script>
export default {
  metaInfo: {
    title: "Welcome to Acctopus",
  },
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import Login from '@/components/dynamic/login.vue';
</script>

<template>
  <PhpServiceView url="?f=global&f2=login" public #default="{result}">
    <Login :result="result"/>
  </PhpServiceView>
</template>

